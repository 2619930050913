<template>
  <v-row class="pa-4">
    <v-col cols="12" md="3"></v-col>
    <v-col cols="12" md="6" class="pt-8">
      <MaterialCard color="primary">
        <template v-slot:heading>
          <v-card-title>
            <strong>404</strong>
            <v-spacer></v-spacer>

            Page Not Found</v-card-title
          >
        </template>
        <div class="pa-2" v-if="this.$route.query.err">
          <v-alert type="info" text outlined class="ma-0">
            {{ this.$route.query.err }}
          </v-alert>
        </div>
      </MaterialCard>
    </v-col>
    <v-col cols="12" md="3"></v-col>
  </v-row>
</template>

<script>
import MaterialCard from "../components/charts/MaterialCard";
export default {
  name: "PageNotFound",
  components: { MaterialCard }
};
</script>

<style scoped></style>
